// Footer.js
import React from 'react';
import './Footer.css';

import { Box, Center, Flex, useColorMode, useColorModeValue, Image } from '@chakra-ui/react';

const Footer = () => {
  return (
  <footer class="site-footer">

      <p>&copy; 2024 Sarvanta. All rights reserved. &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</p>
  </footer>
  );
};

export default Footer;

