// Security.js
import React from 'react';

const Security = () => {
  return (
    <div>
      <h2>Security</h2>
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default Security;

