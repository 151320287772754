// CustomerLogin.js
import React from 'react';

const CustomerLogin = () => {
 
	  window.open("https://owprov-ui.sarvantanet.com:8443",'_blank') 
};

export default CustomerLogin;

