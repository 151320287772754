// Home.js
import React from 'react';

const Blogs = () => {
  return (
    <div>
      <h2>Blogs</h2>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
    </div>
  );
};

export default Blogs;

