// Education.js
import React from 'react';
import './About.css'
import edu from '../assets/EducationSchoolsmall.png';

const Education = () => {
  return (
    <div>
	  <div className="container">
      <h1>Education</h1>
	       <div> <img className="photo" src={edu} alt="Logo" /> </div>
	  </div>
<p>	  In the digital age, educational institutions rely on technology to enhance learning experiences. Sarvanta.ai offers tailored wireless network solutions for schools, ensuring seamless connectivity and security. </p>
<h3> Understanding Educational Needs </h3>

<p> We understand the diverse needs of educational institutions, offering segmentation, device restriction, and content filtering to meet specific requirements. </p>

<h3> Empowering Learning </h3>

<p>Our cloud-managed solutions empower schools to create active learning environments and smart campuses, fostering collaboration and innovation. </p>
<h3> Key Features </h3>
<p> Segmentation and Network Segregation </p>
<p> Device Restriction and Rate Limiting </p>
<p> Secure Authentication and Content Filtering </p>
<h3> Seamless Connectivity </h3>

<p> We provide low-cost, high-security solutions that adapt to changing
educational demands, ensuring uninterrupted connectivity for students and
faculty. </p>

<p> With Sarvanta.ai's wireless solutions, schools can embrace digital transformation confidently, creating engaging learning experiences for the 21st century. </p>
	  
<p>Explore Our “Key Considerations” Before Upgrading Your School's Network </p>
	  <br />
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default Education;

