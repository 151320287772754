// Hospitality.js
import React from 'react';
import './About.css'
import hosp from '../assets/Hospitalitysmall.png';

const Hospitality = () => {
  return (
    <div>
	  <div className="container">
      <h1>Hospitality</h1>
       <div> <img className="photo" src={hosp} alt="Logo" /> </div>
	  </div>
<p>	  Hospitality Wi-Fi refers to the specialized wireless networking services
provided by hotels, resorts, and other businesses in the hospitality industry to
enhance their guests' stay. These services typically include secure and reliable
Wi-Fi connections that allow guests to stay connected throughout their visit. </p>

<p> In today's digital age, hospitality Wi-Fi has evolved from a mere perk to an
essential amenity. With guests averaging 2.9 personal devices each, ensuring
seamless connectivity is paramount. Any shortcomings in Wi-Fi service can
lead to guest dissatisfaction and impact your property's reputation. </p>

<p> Sarvanta.ai offers cutting-edge cloud-managed hospitality Wi-Fi solutions
designed to meet the demands of modern guests. Our solutions provide
exceptionally fast speeds, premium in-room entertainment, and enhance the
competitiveness and profitability of your business. Managed in real-time, our
solutions proactively address service issues, ensuring guest satisfaction across
all your properties. </p>

<p> Each guest is provided with their own Personal Area Network (PAN),
offering a safer and more user-friendly experience compared to typical home
network technology. With automatic authentication, guests enjoy secure,
encrypted access to their individual PAN, both within their room and across
the entire property. Experience seamless connectivity with Sarvanta.ai's
Hospitality Solutions. </p>

<p> Explore our white paper on how Sarvanta revolutionizes Hospitality </p>
	  <br />
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default Hospitality;

