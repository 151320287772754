// Products.js
import React from 'react';
import { Link } from 'react-router-dom';

import './Home.css'; // Import CSS file for styling
import './ButtonStyles.css'; // Import CSS file for styling
import './ScrollingText.css'; // Import CSS file for styling
import './Background.css';
import './BackgroundContainer.css';
import naas from '../assets/Naas.png'; // Import CSS file for styling

const Solutions = () => {
  return (
  <div className="background" >
     <div className="company-dropdown">
       <div className="background-container">
          <Link to="/knowledge"> <button className="borderless-btn">MDU</button> </Link>
          <br />
          <Link to="/knowledge"> <button className="borderless-btn">Hospitality</button> </Link>
          <br />
          <Link to="/knowledge"> <button className="borderless-btn">Education</button> </Link>
          <br />
          <Link to="/knowledge"> <button className="borderless-btn">Warehouses</button> </Link>
          <br />
          <Link to="/knowledge"> <button className="borderless-btn">Small Businesses</button> </Link>
          <br />
       </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
    </div>
  </div>
  );
};

export default Solutions;

