// Home.js
import React from 'react';

const KnowledgeCenter = () => {
  return (
    <div>
	  <h2> KnowledgeCenter </h2>
	  <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
    </div>
  );
};

export default KnowledgeCenter;

