// Products.js
import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';

import './Home.css'; // Import CSS file for styling
import './ButtonStyles.css'; // Import CSS file for styling
import './ScrollingText.css'; // Import CSS file for styling
import './Background.css';
import './BackgroundContainer.css';
import naas from '../assets/Naas.png'; // Import CSS file for styling

const Products = () => {
  return (
  <div className="background" >
     <div className="company-dropdown">
       <div className="background-container">
          <Link to="/naas"> <button className="borderless-btn">Naas Cloud Platform</button> </Link>
          <br />
          <Link to="/security"> <button className="borderless-btn">Security</button> </Link>
          <br />
          <Link to="/nac"> <button className="borderless-btn">NAC</button> </Link>
       </div>
    </div>
  </div>
  );
};

export default Products;

