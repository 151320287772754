// MDU.js
import React from 'react';
import './About.css'
import mdu from '../assets/MDUsmall.png';

const MDU = () => {
  return (
    <div>
	  <div className="container">
      <h1>MDU</h1>
	  <div> <img className="photo" src={mdu} alt="Logo" /> </div>
	  </div>
<p> Elevate Your MDU Connectivity with Cutting-Edge Cloud NaaS
Solutions </p>

 <p> In the realm of Multi-Dwelling Units (MDUs), reliable wireless
connectivity is not just a luxury but a necessity. However, many
operators face challenges in managing and supporting their wireless
networks effectively. This can lead to service disruptions, sluggish
connections, and increased costs.</p>

<p> Managed Wi-Fi services offer a solution by extending robust wireless
Internet access to all occupants of MDUs. By implementing a
community-wide MDU network, operators can provide a sought-after
amenity that attracts tenants, boosts revenues, and sets their
properties apart from competitors. </p>

<p> With Sarvanta.ai's Cloud NaaS solutions, MDU operators gain better
control and visibility through a single pane of glass. Our integrated AI-
based radio management ensures efficient network operation,
seamless coverage, and enhanced user experience. By partnering
with Sarvanta.ai, MDU operators can deliver a superior resident
experience while optimizing costs and ensuring long-term reliability.
Explore our white paper on how to unleash your business potential
and enhance your MDU with our offered Wifi solution here </p>
	  <br />
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default MDU;

