// Home.js
import React from 'react';
import './About.css';
import { Container, Row, Col } from 'react-bootstrap';
import naas from '../assets/Landing-page-globe.png';
import about from '../assets/About-Companysmall.png';

const About = () => {
  return (
  <div>
      <div className="container">
      <h1 >Who We Are</h1>
      <div ><img className="photo" src={about} alt="Logo" /></div>
     </div>
	  <p>Welcome to Sarvanta.ai, a premier provider of cloud-based Network-as-a-Service (NaaS) solutions. Our expertise lies in wired and wireless connectivity, offering state-of-the-art technology. Our NaaS platform serves a variety of verticals, including hospitality, retail, education, healthcare, and warehouses, catering to the unique networking needs of each </p>
	  <p> Along with our measurable realtime Quality Of Experience of the clients, Our innovative AI/ML-based radio resource management ensures optimal performance and reliability across your network infrastructure.Additionally, our cost-effective white box access points provide high-quality connectivity without compromising your budget </p>
      <p> Established in 2023, Sarvanta.ai is led by industry veterans and seasoned leaders with extensive experience in building massively scalable Cloud Native Platforms and networking technology. Our team includes individuals who have held key roles at renowned companies such as Cisco, Comcast, Ruckus Wireless, and Fujitsu and Plume WiFi. With our commitment to excellence and cutting-edge technology, we are dedicated to revolutionizing the networking landscape and empowering businesses to thrive in the digital era </p>
  </div>
  );
};

export default About;

