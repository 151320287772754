// Home.js
import React from 'react';
import Naaspdf from './NaaS_Cloud_whitepaper.pdf';
import Edupdf from './Education_cloud_whitepaper.pdf';
import Hospdf from './Hospitality_cloud_whitepaper.pdf';
import Mdupdf from './MDU_NaaS_whitepaper.pdf';
import Warepdf from './Warehouse_cloud_whitepaper.pdf';
const WhitePapers = () => {
  return (
    <div>
      <h2>WhitePapers</h2>
	  <a href={Naaspdf} target="_blank" rel= "noopener noreferrer" >NaaS Cloud White Paper</a>
          <br />
	  <a href={Mdupdf} target="_blank" rel= "noopener noreferrer" >MDU White Paper</a>
          <br />
	  <a href={Edupdf} target="_blank" rel= "noopener noreferrer" >Education White Paper</a>
          <br />
	  <a href={Hospdf} target="_blank" rel= "noopener noreferrer" >Hospitality White Paper</a>
          <br />
	  <a href={Warepdf} target="_blank" rel= "noopener noreferrer" >Warehouses White Paper</a>
	           <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
    </div>
  );
};

export default WhitePapers;

