// App.js
import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';

import Products from './pages/Products';
import NaasCloud from './pages/NaasCloud';
import NAC from './pages/NAC';
import Security from './pages/Security';
import OpenHardware from './pages/OpenHardware';

import Company from './pages/Company';
import About from './pages/About';
import Contact from './pages/Contact';
import Partners from './pages/Partners';
import News from './pages/News';

import Solutions from './pages/Solutions';
import MDU from './pages/MDU';
import Education from './pages/Education';
import Warehouses from './pages/Warehouses';
import Hospitality from './pages/Hospitality';

import Resources from './pages/Resources';
import KnowledgeCenter from './pages/KnowledgeCenter';
import WhitePapers from './pages/WhitePapers';
import Blogs from './pages/Blogs';


import CustomerLogin from './pages/CustomerLogin';

import './App.css';

const App = () => {
	// Place this code inside the react component where you want to render the widget
 // Typically you should do it as top level component like App.js
 useEffect(() => {
   window.plugSDK.init({
     // Please ensure you replace the app_id with your unique app id
     app_id: "don:core:dvrv-us-1:devo/2Ti0Bk6g:plug_setting/1",
   });
 }, []);
  return (
    <Router>
      <div>
        <Header />
        <Routes>
	  <Route path="/" element={<Home />} />
        <Route path="/company" element={<Company />} />
        <Route path="/about" element={<About />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/products" element={<Products />} />
        <Route path="/naascloud" element={<NaasCloud />} />
        <Route path="/nac" element={<NAC />} />
        <Route path="/security" element={<Security />} />
        <Route path="/openhardware" element={<OpenHardware />} />

        <Route path="/solutions" element={<Solutions />} />
        <Route path="/mdu" element={<MDU />} />
        <Route path="/hospitality" element={<Hospitality />} />
        <Route path="/education" element={<Education />} />
        <Route path="/warehouses" element={<Warehouses />} />

        <Route path="/resources" element={<Resources />} />
        <Route path="/knowledgecenter" element={<KnowledgeCenter />} />
        <Route path="/whitepapers" element={<WhitePapers />} />
        <Route path="/blogs" element={<Blogs />} />

        <Route path="/login" element={<CustomerLogin />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;

