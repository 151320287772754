// NAC.js
import React from 'react';
import nac from '../assets/NACsecuritysmall.png';

const NAC = () => {
  return (
    <div>
	  <div className="container">
           <h1>Security</h1>
	  <div> <img className="photo" src={nac} alt="Logo" /> </div>
	  </div>
	  <p> We offer two security solutions for Network Access Control </p> 
	  <h3> PacketFence </h3> 
	  <p> Packetfence is a fully supported, trusted, Free and Open Source network access control (NAC) solution. Boasting an impressive feature set including a captive-portal for registration and remediation, centralized wired and wireless management, 802.1X support, layer-2 isolation of problematic devices, integration with the Snort IDS and the Nessus vulnerability scanner; PacketFence can be used to effectively secure networks - from small to very large heterogeneous networks.</p>
	  <br />
	  <h3> FreeRadius </h3> 
	  <p> 802.1X support for both Wired and Wireless Networks</p>
	  <br />
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default NAC;

