// OpenHardware.js
import React from 'react';
import './About.css';
import naascloud from '../assets/NaaSCloudsmall.png';
import eap102 from '../assets/EAP102.png';
import eap101 from '../assets/EAP101.png';
import eap104 from '../assets/EAP104.png';
import ecs4100 from '../assets/ECS4100.png';
import ecs1100 from '../assets/ECS1100.png';
import wf196 from '../assets/WF196.png';
import wf188 from '../assets/WF188N.png';
import wf186 from '../assets/WF186.png';
import wf660 from '../assets/WF660.png';

const OpenHardware = () => {
  return (
    <div>
	  <div className="container">
	  <h1> Open Hardware </h1>
	  <div><img className="photo" src={naascloud} alt="Logo" /> </div>
	  </div>
	  <p> 
Sarvanta offers expansive network of access points and POE switches, coupled with Cloud native management, Zero Touch Provisioning, disaggregated architecture, and automation-driven operations, leads to significant reductions in both initial deployment costs (CapEx) and ongoing operational expenses (OpEx). This approach not only avoids vendor lock-in but also facilitates multi-vendor deployments, empowering organizations to optimize their Wi-Fi infrastructure expenditures.
	  </p>
<h2>Supported Access Points </h2>
<h2>Ceiling mount Access Points </h2>
<p>CIG / Actiontec WF-196 tri-band 4×4 MU-MIMO 802.11ax (WiFI 6E)</p>
	  <img  src={wf196} alt="Logo" />
<p>CIG / Actiontec WF-188N dual-band 2x2:2 MU-MIMO 802.11ax (WiFI 6)</p>
	  <img  src={wf188} alt="Logo" />
<p>Edgecore EAP102 dual-band 4x4:4 MU-MIMO 802.11ax (WiFi 6) 1 x 2.5GbE PoE WAN 1 x 2.5GbE LAN</p>
	  <img  src={eap102} alt="Logo" />
<p>Edgecore EAP101 dual-band 2x2:2 UL MU-MIMO 1 x 2.5GbE PoE WAN 2 x GbE LAN</p>
	  <img  src={eap101} alt="Logo" />
<h2>Wall Plate mount Access Points </h2>
<p>CIG / Actiontec WF-186 dual-band 2x2:2 MU-MIMO 802.11ax (WiFI 6)</p>
	  <img  src={wf186} alt="Logo" />
<p>Edgecore EAP104 dual-band 2x2:2 MU-MIMO 802.11ax (WiFi 6) 1 x GbE PoE WAN 4 x GbE with 1 x PoE Out LAN</p>
	  <img  src={eap104} alt="Logo" />
<h2>Outdoor Access Points </h2>
<p>CIG / Actiontec WF-660AG dual-band 2x2:2 MU-MIMO 802.11ax (WiFI 6)</p>
	  <img  src={wf660} alt="Logo" />
<p>Edgecore OAP101-6E tri-band 2x2:2 MU-MIMO 802.11ax (WiFi 6E)</p>
<h2>Managed PoE Switches </h2>
<p>Sarvanta supports below openLAN Switching compliant switches</p>
<p>Edgecore ECS 4100 – 52P, 48 x 1Gbps (PoE), 4 x 1 Gbps SFP, af/at 370W</p>
<p>Edgecore ECS 4100 – 28P, 24 x 1Gbps (PoE), 4 x 1 Gbps SFP, af/at 190W</p>
	  <img  src={ecs4100} alt="Logo" />
<p>Edgecore ECS 1100 – 10P, 8 x RJ-45 10/100/1000 Ports (PoE) 2 x 1G SFP Port</p>
	  <img  src={ecs1100} alt="Logo" />
    </div>
  );
};

export default OpenHardware;

