// Home.js
// Home.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Home.css'; // Import CSS file for styling
import './ButtonStyles.css'; // Import CSS file for styling
import './About.js';

import './ScrollingText.css'; // Import CSS file for styling
import './Background.css';
import './BackgroundContainer.css';
import naas from '../assets/Naas.png'; // Import CSS file for styling
//
const Company = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  return (
	  <div className="background" >
    <div className="company-dropdown">
	  <div className="background-container">
	  <Link to="/about"> <button className="borderless-btn">About</button> </Link>
	  <br />
	  <Link to="/partners"> <button className="borderless-btn">Partners</button> </Link>
	  <br />
	  <Link to="/contact"> <button className="borderless-btn">Contact</button> </Link>
	  <br />
	  <Link to="/news"> <button className="borderless-btn">News</button> </Link>
	  </div>
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
    </div>
	  </div>
  );
};

export default Company;

