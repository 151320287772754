// Contact.js
import React from 'react';

const Contact = () => {
  return (
    <div>
      <h2>Contact</h2>
       <a href="mailto:info@sarvanta.ai" target="_blank" rel="noopener noreferrer" >info@sarvanta.ai</a>
       <p>710 Lakeway Dr suite #200 </p>
       <p>Sunnyvale, CA 94085 </p>
       <p>Also You can send us a message using the Chatbot below</p>
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default Contact;

