// Partners.js
import React from 'react';
import './About.css';
import partner from '../assets/Partnerssmall.png';

const Partners = () => {
  return (
    <div>
     <div className="container">
      <h1 >Partners</h1>
      <div ><img className="photo" src={partner} alt="Logo" /></div>
     </div>
      <h2> Why Partner ? </h2>
      <p> At Sarvanta, we recognize the integral role our partners play in driving our solutions to market effectively. As a valued partner, you are pivotal in connecting businesses with our innovative connectivity solutions. To empower you in this mission, we've developed the Sarvanta Elevate partner program. This comprehensive program is designed to equip your teams with the tools, training, and incentives needed to excel in marketing and delivering Sarvanta solutions to end customers. From training resources to certification programs, events, and rewards, we ensure that you have everything you need to succeed. </p>
      <h2>What We Offer? </h2>
<p> Established in 2023, Sarvanta has quickly emerged as a leader in cloud-driven connectivity solutions. Our mission is simple yet powerful: to strengthen your network infrastructure with seamless connectivity and innovative, user-centric designs. By partnering with Sarvanta, you gain access to cutting-edge cloud-managed solutions, a diverse range of cost-effective Whitebox hardware options, and comprehensive channel-driven support. With Sarvanta by your side, you can focus on growing your business and serving your clients while we handle the rest.we are committed to providing our partners with the resources they need to thrive in today's competitive landscape</p>

	  <br/>
	  <br/>
	  <br/>
	  <br/>
    </div>
  );
};

export default Partners;

