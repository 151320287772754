// Warehouses.js
import React from 'react';
import './About.css'
import warehouse from '../assets/Warehousesmall.png';

const Warehouses = () => {
  return (
    <div>
	  <div className="container">
      <h1>Warehouses</h1>
   <div> <img className="photo" src={warehouse} alt="Logo" /> </div>
	  </div>
	  <p> Sarvanta offers specialized Wi-Fi solutions tailored to meet the unique 
needs of warehouses. Our solutions are designed to address signal 
coverage challenges, provide sufficient bandwidth, and ensure reliable 
connectivity throughout the facility. With Sarvanta, warehouses can 
optimize their operations, enhance productivity, and deliver 
exceptional service to their customers. Explore our white paper for 
detailed insights into implementing warehouse Wi-Fi solutions. </p>
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default Warehouses;

