// NaasCloud.js
import React from 'react';
import './About.css';
import naascloud from '../assets/NaaSCloudsmall.png';

const NaasCloud = () => {
  return (
    <div>
	  <div className="container">
	  <h1> NaaS Cloud </h1>
	  <div><img className="photo" src={naascloud} alt="Logo" /> </div>
	  </div>
	  <p> Empowering MSPs and Cloud Providers, our NAAS Cloud introduces a transformative paradigm shift with Network-as-a-Service (NaaS) for enterprise clients. Our cloud solution is engineered to help deliver Scalability, Economics, Security, Flexibility, Agility, and Simplified Management, redefining how providers approach offering WiFi and Wired network services</p>
	<p> Our cloud-based NaaS platform enables enterprises to deploy WiFi and Wired network services with an internet connection. Out AI platform helps to offer state of the art networking services with unprecedented operational efficiency in today's digital landscape.Sarvanta.ai can help facilitate this shift by providing comprehensive NaaS solutions tailored to the unique needs of businesses, empowering them to embrace the benefits of cloud networking with confidence.
	  </p>
	  <p>
Explore our detailed white paper on various aspects of NaaS
Cloud solution
	  </p>
	  <br />
	  <br />
	  <br />
    </div>
  );
};

export default NaasCloud;

