// Home.js

import React, { useState,useEffect } from 'react';
import { Box, Center, Flex, useColorMode, useColorModeValue, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import './Home.css'; // Import CSS file for styling
import './ButtonStyles.css'; // Import CSS file for styling
import './About.js';
import './About.css';

import './ScrollingText.css'; // Import CSS file for styling
import './Background.css';
//import naas from '../assets/Naas.png'; // Import CSS file for styling

class Home extends React.Component {
  render() {
    return (
   <div style={{overflow: 'hidden'}}>
       <div className="background" style={{ marginLeft: '10px' }}>
          <p>&nbsp; </p>
          <p>&nbsp; </p>
        <h1 >Network as a Service For Enterprise Of Any Size</h1>
        <h1>Seamless WiFi Connectivity</h1>
        <h1>AI-Powered Insights Elevating Business Outcomes</h1>
      </div>
   </div>
    );
 };
};

export default Home;

